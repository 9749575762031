<template>
  <dash-page-new
      :subtitle="$t('Storage')"
      :root="$t('Dashboard')"
      :title="$t('Storage') + ': ' + storageSize + ' / ' + storageSizeQuota + ' GB' "
      icon="mdi-server"
      :filters="filters"
      @filter="filtersData = $event"
      :filters-input="filtersData"
      :filters-search="true"
      :filters-search-ajax="initPage"
    >

    <template #mobile.action>
      <ft-select
          v-if="selectedFolder !== 'shared'"
          :items="headerActionsSelect"
      >
      <v-btn :color="wsATTENTION" icon>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
        </ft-select>
    </template>
    <template #header_action.desktop>

      <ft-select
          v-if="selectedFolder !== 'shared'"
          :items="headerActionsSelect"
      >
        <ws-button
            label="Add"
            :click-stop="false"
            elevation="0"  dark
        />
      </ft-select>

    </template>

    <template #default>

      <div class="fill-height" style="position : relative">
        <!-- Breadcrumbs-->
        <v-sheet width="100%" class="d-flex align-center" style="position : absolute; z-index : 2" color="transparent" height="60">
          <div class="d-flex align-center">
            <div class="d-flex align-center" v-for="(item , i) in location" :key="i">
              <h5 @click="gotoBreadcrumbFolder(item)" class="pointer linkHover" :style="`color : ${wsACCENT}`">
                {{ item.name === 'Shared' ? $t('file_browser.shared_folder') : item.name      }}
              </h5>
              <v-icon>mdi-chevron-right</v-icon>
            </div>
            <h5>{{  selectedFolderName === 'Shared' ? $t('file_browser.shared_folder') : selectedFolderName   }}</h5>
          </div>
        </v-sheet>
        <!-- Browser-->
        <v-sheet
            :style="displayUpload ? 'padding-bottom : 200px' : ''"
            style="transition: all 0.3s ease; position:relative"
            class="flex-grow-1 fill-height pt-16" color="transparent"
        >
          <div  class="d-flex flex-row fill-height">

            <v-sheet v-show="!SM && foldersCount > 0"  color="transparent" min-width="300"  class="fill-height overflow-y-auto pr-5">
              <storage-side-navigation
                  :selected-folder="selectedFolder"
                  :update-trigger="sidebarUpdateTrigger"
                  @select="gotoNavigationFolder"
                  @update-length="foldersCount = $event"
              />
            </v-sheet>

            <!-- BROWSER FILES TABLE -->
            <v-sheet color="transparent" width="100%" class="flex-grow-1 fill-height  d-flex flex-column">

              <!-- Table Header -->

              <div class="v-data-table wsHoverLight v-data-table--dense v-data-table--has-bottom theme--light ">
                <div class="v-data-table__wrapper">
                  <portal-target name="storage_table_header" tag="table"  />
                </div>
              </div>

              <!-- Table Content -->
              <v-sheet
                  :style="`border-left : 1px solid ${wsBACKGROUND} !important;border-right : 1px solid ${wsBACKGROUND} !important;border-bottom : 1px solid ${wsBACKGROUND} !important`"
                  style="border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;"
                  color="transparent" class="flex-grow-1 overflow-y-auto">

                <ws-data-table
                    :items="itemsFiltered"
                    :headers="headers"
                    :row-action="openAction"
                    :context-actions-select="itemActionsSelectFiltered"
                    footer-portal="storage-table-footer"
                    class="mb-n5"
                    :selectable="!IS_BUSINESS_CLIENT"
                    @select="bufferItemsIds = $event.items"
                    @select-item="selectedItem = $event"
                    :selected-rows="bufferItemsIds"
                    :multiple-actions="multipleActionsSelect"
                    :total="total"
                    :load-more="loadMore"
                >

                  <template  #header.after="{colspanValue}">
                    <v-hover v-if="selectedFolder" #default="{hover}">
                      <tr @click="goToPreviousFolder">
                        <td class="pointer" :colspan="colspanValue"
                            :style="`background : ${hover ? wsBACKGROUND : wsLIGHTCARD}; padding-left: ${IS_BUSINESS_CLIENT ? 24 : 46}px;`"
                            style="border-bottom : 1px solid var(--wsBACKGROUND);border-top : 1px solid var(--wsBACKGROUND);"
                        >
                          <h5>
                            <v-icon class="mr-2" :color="wsATTENTION"> mdi-folder-outline </v-icon>
                            ...
                          </h5>
                        </td>
                      </tr>
                    </v-hover>

                    <v-hover v-if="!selectedFolder && !filtersData.search && !MODULE_PERMISSIONS(['MODULE_STORAGE__FULL_ACCESS'] , true)" #default="{hover}">
                      <tr @click="gotoNavigationFolder(sharedFolderItem)">
                        <td class="pointer" :colspan="colspanValue"
                            :style="`background : ${hover ? wsBACKGROUND : wsLIGHTCARD}; padding-left: ${IS_BUSINESS_CLIENT ? 24 : 46}px;`"
                            style="border-bottom : 1px solid var(--wsBACKGROUND);border-top : 1px solid var(--wsBACKGROUND);"
                        >
                          <h5>
                            <v-icon :color="wsACCENT" class="mr-1"> mdi-folder-account </v-icon>
                            {{  $t('file_browser.shared_folder')  }}
                          </h5>
                        </td>
                      </tr>
                    </v-hover>


                  </template>

                  <template v-if="false"  #header="{props , on, selectable,allSelected,semiSelected,enableDrag,multipleActions,colspanValue,countSelected,countTotal}">
                    <portal to="storage_table_header">
                      <ws-table-header
                          :headers="headers"
                          :props="props" :on="on"
                          :selectable="selectable"
                          :all-selected="allSelected"
                          :semi-selected="semiSelected"
                          :enable-drag="enableDrag"
                          :multiple-actions="multipleActions"
                          :colspan-value="colspanValue"
                          :count-selected="countSelected"
                          :count-total="countTotal"
                      />
                    </portal>
                  </template>

                  <template #item.name="{item}">
                    <div class="d-flex align-center" :class="[{'pl-5' : IS_BUSINESS_CLIENT}]">
                      <v-icon class="mr-2" :color="bufferItemsIds.includes(item.uuid) ? wsACCENT : wsATTENTION">{{ getIcon(item.mime) }}</v-icon>
                      <div>
                        <h5  class="text-no-wrap"
                             :style="bufferItemsIds.includes(item.uuid) ? `color : ${wsACCENT}` : null">
                          {{ SHORTEN_FILE_NAME(item.name) }}
                        </h5>
                        <h6 v-if="false" class="font-weight-regular">Новітні технології в javascript</h6>
                      </div>

                    </div>

                  </template>

                  <template #item.relations_count="{item}">
                    <div>
                      <h5 v-if="!item.is_folder &&item.relations_count > 0" class="text-center" > {{ item.relations_count   }}</h5>
                    </div>
                  </template>

                  <template #item.is_public="{item}">
                    <div class="d-flex justify-center">
                      <v-icon small v-if="item.is_public" :color="wsACCENT" >mdi-eye</v-icon>
                    </div>
                  </template>

                  <template #item.shared_users_count="{item}">
                    <h5 class="text-no-wrap">
                      {{
                        item.shared_users_count > 0
                            ? `${$t('Users')}: ${item.shared_users_count}`
                            :  $t('file_browser.not_shared')
                      }}
                    </h5>
                  </template>

                  <template #item.size="{item}">
                    <div >
                      <h5 class="text-no-wrap" v-if="!item.is_folder" >{{ PARSE_FILE_SIZE(item.size)    }}</h5>
                    </div>
                  </template>

                  <template #item.date_added="{item}">
                      <h5  class="font-weight-medium text-no-wrap"> {{ MONTH_DAY_TIME(item.date_added ) }}</h5>
                  </template>

                  <template #item.action="{item}">
                    <ft-select
                        :items="itemActionsSelectFiltered"
                        @expand="selectedItem = item"
                    >
                      <v-btn :color="wsACCENT"  height="24" min-width="24" width="24" text>
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </ft-select>
                  </template>

                </ws-data-table>
              </v-sheet>

              <!-- Table Footer -->
              <v-sheet min-height="60px" :style="`border : 1px solid ${wsBACKGROUND}`" class="px-5 d-flex  align-center wsRoundedLight mt-4">
                <portal-target name="storage-table-footer"></portal-target>
              </v-sheet>


            </v-sheet>
          </div>
        </v-sheet>
      </div>


    </template>

    <template #dialog>
      <!-- File Details Dialog-->
      <wsDialog
          :title="$t('FileDetails')"
          v-if="displayDialog"
          v-model="displayDialog"
          @save="editFile()"
          @delete="deleteFile"
          :show-delete="!IS_BUSINESS_CLIENT"
          not-centered
          :width="700"

      >

        <portal to="file_browser_dialog_info" :disabled="!displayFilePreview">
          <ws-text-field
              v-if="!IS_BUSINESS_CLIENT"
              v-model="selectedFile.name"
              :label="$t('Name')"
              :placeholder="$t('FileName')"
              width="100%"
              background-color="transparent"
          >
            <template #append>
              <h5 class="mt-1" v-if="selectedFile.ext !== selectedFile.name">{{ selectedFile.ext }}</h5>
            </template>
          </ws-text-field>

          <div >

            <div v-if="IS_BUSINESS_CLIENT" class="mr-6">
              <h5 class="font-weight-medium mt-5" :style="`color : ${wsACCENT}`"> {{ $t('FileName') }}: </h5>
              <h5> {{ selectedFile.name  }} asfsdf: {{ selectedFile.ext }}</h5>
            </div>

           <div class="mr-6">
             <h5 class="font-weight-medium mt-5" :style="`color : ${wsACCENT}`"> {{ $t('FileSize') }}: </h5>
             <h5> {{ PARSE_FILE_SIZE(selectedFile.size)  }}</h5>
           </div>

            <div>
              <h5 class="font-weight-medium mt-5" :style="`color : ${wsACCENT}`"> {{ $t('FileType') }}: </h5>
              <h5> {{  getFileType(selectedFile.mime)   }}</h5>
            </div>


          </div>

        </portal>

        <div v-if="!IS_BUSINESS_CLIENT" class="d-flex align-end flex-row">
          <ws-navigation-header
              v-if="displayFilePreview"
              v-model="previewFileNavigation"
              :items="previewFileNavigationItems"
          />
          <div class="flex-grow-1" style="border-bottom : 4px solid var(--wsBACKGROUND)" ></div>
          <ft-select
              @input="downloadFile(selectedFile , $event)"
              :items="selectedFileQualities"
              :disabled="selectedFileQualities.length === 0"
          >
            <ws-button
                @click="selectedFileQualities.length > 0 ? null : downloadFile(selectedFile)"
                :disabled="LOADING"
                :label="!SM ? 'Download' : null"
                :color="wsACCENT"
                left-icon="mdi-download"
                style="border-radius: 0;border-bottom : 4px solid var(--wsBACKGROUND)"
                text
                :click-stop="false"
                min-width="30px"
            />
          </ft-select>

        </div>
        <ws-navigation
            v-if="displayFilePreview"
            v-model="previewFileNavigation"
            :items="previewFileNavigationItems"
            hide-header
        >
          <template #item.preview>
            <v-sheet  v-if="previewType === 'image'" class="image-with-chessboard">
              <v-img  contain max-height="300px" :src="selectedFile.url" />
            </v-sheet>
            <wsVideo v-if="previewType === 'video'" :file="selectedFile" />
            <ws-pdf
                v-if="previewType === 'pdf'"
                :file="selectedFile"
                :height="400"
            />
          </template>
          <template #item.info>
            <portal-target name="file_browser_dialog_info"></portal-target>
          </template>
        </ws-navigation>

        <template v-if="IS_BUSINESS_CLIENT" #buttons>
          <ft-select
              @input="downloadFile(selectedFile , $event)"
              :items="selectedFileQualities"
              :disabled="selectedFileQualities.length === 0"
          >
            <ws-button
                @click="selectedFileQualities.length > 0 ? null : downloadFile(selectedFile)"
                :disabled="LOADING"
                label="Download"
                left-icon="mdi-download"
                class="mr-2"
                outlined
                :click-stop="false"
                min-width="30px"
            />
          </ft-select>
          <ws-button @click="displayDialog = false" label="Close" outlined></ws-button>
        </template>

      </wsDialog>

      <!-- Upload File Dialog -->
      <ws-dialog
          v-model="displayUpload"
          v-if="displayUpload"
          :title="$t('UploadFile')"
          no-navigation
      >
        <ws-file-uploader
            @success="addFile"
            :folder="selectedFolder"
        />
      </ws-dialog>
      <!-- Folder Dialog-->
      <ws-dialog
          v-model="displayFolderDialog"
          @save="addEditFolder"
          :save-text="newFolder ? $t('Create') : $t('Save')"
          :title="newFolder ? $t('AddFolder') : $t('EditFolder')"
      >
        <ws-text-field
            v-model="folderEntityData.name"
            :label="$t('Name')"
            :placeholder="$t('file_browser.folder.enter_name')"
        />
      </ws-dialog>
      <!-- Move to folder Dialog-->
      <ws-dialog
          v-if="displayMoveToFolder"
          v-model="displayMoveToFolder"
          :title="$t('file_browser.move_to_folder')"
          hide-buttons
          width="400"
          max-height="400px"
          height="90%"
          not-centered
      >

        <storage-side-navigation
            @select="moveToFolder"
            :buffer-items="bufferItemsIds"
            full-width
        />

      </ws-dialog>
      <!-- Access Dialog-->
      <file-access-dialog
          v-if="displayFileAccessDialog"
          v-model="displayFileAccessDialog"
          :item="selectedItem"
          @update-shared-users="updateSharedCount"
      />

      <!-- DELETE -->
      <!-- Delete Dialog-->
      <ws-dialog
          v-model="displayDeleteDialog"
          @delete="handleDelete"
          display-confirm-delete
          close-delete
          :title="$t(isSelectedFolder ? 'DeleteFolder' : 'DeleteFile')"
      >
        <template #confirm-delete>
          <h4 class="text-center wsDARKER mt-5">{{ $t('PleaseConfirmDeleteAction') }}</h4>
          <h5 class="font-weight-regular text-center mt-5 wsACCENT">{{ selectedItem.name }}</h5>
        </template>
      </ws-dialog>
      <!-- Multiple Delete Dialog-->
      <ws-dialog
          v-model="displayMultipleDeleteDialog"
          @delete="handleMultipleDelete"
          display-confirm-delete
          close-delete
          :title="$t('Delete')"
      >
        <template #confirm-delete>
          <h4 class="text-center wsDARKER mt-5 mb-5">{{ $t('PleaseConfirmDeleteAction') }}</h4>

          <h5 v-for="(item,i) in bufferItems" :key="'delete' + i"
              :class="[{'font-weight-regular' : !item.is_folder}]"
              class="wsACCENT">
            {{ item.name }}
          </h5>

        </template>
      </ws-dialog>

    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import wsTableHeader from "@/components/UI/tables/wsTableHeader";
import storageSideNavigation from "@/components/pages/businessDashboard/businessStorage/storageSideNavigation";
import fileAccessDialog from "@/components/pages/businessDashboard/businessStorage/fileAccessDialog";
export default {
  name: "settingsStorage",
  components : {
    wsTableHeader,
    storageSideNavigation,
    fileAccessDialog
  },
  data() {
    return {
      foldersCount : 0,
      displayDialog : false,
      displayDeleteDialog : false,
      displayMultipleDeleteDialog : false,

      selectedFile : {},
      items : [],
      total : 0,
      folders : [],
      storageSize : 0,
      storageSizeQuota : 0,
      displayUpload : false,

      filtersData : {},
      // folders
      displayFolderDialog : false,
      newFolder : false,
      folderEntityData : {},
      selectedFolder : null,
      selectedFolderName : '',
      returnFolder : null,
      selectedItem : {},
      bufferItemsIds : [],
      location : [],
      locationNames : [],
      displaySelect : false,
      sidebarUpdateTrigger : 0,
      displayMoveToFolder : false,
      // file access
      displayFileAccessDialog: false,
      previewFileNavigation : 'settings',
    }
  },
  computed : {
    headers() {
      let items = [
        { text : this.$t('Name') , value : 'name' , padding : 4 },
        { text : this.$t('file_browser.relations') , value : 'relations_count' , width : 10 },
        { text : this.$t('file_browser.is_public') , value : 'is_public' , width : 10  },
        { text : this.$t('file_browser.shared_to') , value : 'shared_users_count' , width : 10  },
        { text : this.$t('FileSize') , value : 'size' , width : 10  },
        { text : this.$t('DateCreated') , value : 'date_added' , width : 10  },
        { value : 'action' , width : 10 , sortable : false  },
      ]

      if (this.IS_BUSINESS_CLIENT) {
        items = items.filter(el => el.value !== 'action')
      }

      return items
    },
    itemsFiltered() {
      let items = this.COPY(this.items)

      return items

    },
    filters() {
      return [
        { text : this.$t('FileType')  , value : 'mime' , type : 'select' , items : [
            { text : this.$t('Video_') , value : 'video' },
            { text : this.$t('Image') , value : 'image' },
            { text : this.$t('Pdf') , value : 'pdf' },
          ] },
      ]
    },

    headerActionsSelect() {
      return [
        { text : this.$t('UploadFile') , action : this.openUpload },
        { text : this.$t('AddFolder') , action  : this.openAddFolder }
      ]
    },
    itemActionsSelectFiltered() {
      if (this.IS_BUSINESS_CLIENT) {
        return []
      }
      if ( this.selectedItem.is_return) {
        return []
      }

      let items = [...this.itemActionsSelect]

      let filterSingle = this.bufferItemsIds.length === 0
      let filterMultiple = this.bufferItemsIds.length > 0
      let filterFolder = this.selectedItem.mime === 'folder'
      // let filterNotFolder = this.selectedItem.mime !== 'folder'
      let filterPublic = this.selectedItem.is_public

      if (filterSingle) {
        items = items.filter(el => el.filter.includes('single'))
        if (filterPublic) {
          items = items.filter(el => el.filter.includes('public'))
        }
        if ( filterFolder ) {
          items = items.filter(el => !el.filter.includes('no_folder'))

        }
      }
      else if (filterMultiple) {
        if (filterFolder) {
          items = items.filter(el => el.filter.includes('folder_multiple'))
        } else {
          items = items.filter(el => el.filter.includes('multiple'))
        }


      }

      return items
    },
    itemActionsSelect() {
      return [
        { text : this.$t('Select'),
          icon : 'mdi-checkbox-marked-outline' ,
          action : () =>  this.selectItem(this.selectedItem),
          filter : ['multiple' , 'single'  ]
        },
        {
          text : this.$t('Paste'),
          icon : 'mdi-folder-move',
          action : this.moveToFolder,
          filter : ['folder_multiple',]
        },
        { text : this.$t('Details'),
          icon : 'mdi-cog',
          action : () =>  this.openAction(this.selectedItem , true),
          filter : ['single']
        },
        { text : this.$t('file_browser.share'),
          icon : 'mdi-share-variant-outline',
          action : () => this.openFileAccessDialog(this.selectedItem),
          filter : ['single']
        },
        { text : this.$t('Download'),
          icon : 'mdi-download',
          action : () =>  this.downloadFile(this.selectedItem),
          filter : ['single','no_folder']
        },
        {
          text : this.$t('Link'),
          icon : 'mdi-link',
          action : () => this.CLIPBOARD(this.selectedItem.url),
          filter : ['public']
        },
        { text : this.$t('file_browser.move_to_folder') ,
          icon : 'mdi-folder-move-outline' ,
          action : () =>  this.openMoveToFolder([this.selectedItem]),
          filter : ['single']
        },
        { text : this.$t('Delete') ,
          icon : 'mdi-delete' ,
          action :  this.openDeleteDialog,
          filter : ['single' ]
        }
      ]

    },
    multipleActionsSelect() {
      if (this.IS_BUSINESS_CLIENT) {
        return null
      }
      let items =  [
        { text : 'file_browser.move_to_folder' ,
          icon : 'mdi-folder-outline',
          action : () => {
            this.displayMoveToFolder = true
          }
        },
        { text : 'Delete' ,
          icon : 'mdi-delete-outline',
          action : () => {
            this.displayMultipleDeleteDialog = true
          }
        }
      ]

      return items

    },

    previewFileNavigationItems() {
      let items =  [
        { text : this.$t('Details') , value : 'info' },
      ]
      if (this.displayFilePreview) {
        items.unshift({ text : this.$t('Preview') , value : 'preview' }, )
      }
      return items
    },
    previewType() {
      if ( !this.selectedFile) {
        return null
      }
      if ( !this.selectedFile.mime ) {
        return null
      }

      if ( this.selectedFile.mime.includes("image")  ) {
        return 'image'
      }

      if ( this.selectedFile.mime.includes("video")   ) {
        return 'video'
      }

      if ( this.selectedFile.mime.includes("pdf")   ) {
        return 'pdf'
      }

      return null
    },
    displayFilePreview() {
      if ( !this.selectedFile) {
        return false
      }
      if ( !this.selectedFile.mime ) {
        return false
      }

      return this.selectedFile.mime.includes('video') ||
             this.selectedFile.mime.includes('image') ||
          this.selectedFile.mime.includes('pdf')


    },
    bufferItems() {
      return this.items.filter( el => this.bufferItemsIds.includes(el.uuid))
    },
    isBrowsingShared() {
      return this.location.filter(el => el.uuid === 'shared').length > 0 || this.selectedFolder === 'shared'
    },

    isSelectedFolder() {
      return this.selectedItem.mime === 'folder'
    },

    ajaxFiltersInput() {
      let data = {
        'parent' : this.selectedFolder,
        'is_shared' : this.isBrowsingShared
      }
      if (this.filtersData.search) {
        data.search = this.filtersData.search
      }

      if (this.filtersData.mime) {
        data.mime = this.filtersData.mime
      }

      return data
    },

    sharedFolderItem() {
      return {
        uuid: "shared",
        name : "Shared",
        location: []
      }
    },

    selectedFileQualities() {
      let items = []
      if ( !this.selectedFile ) {
        return
      }
      if ( !this.selectedFile.qualities ) {
        return
      }
      this.selectedFile.qualities.forEach(el => {
        let text = el === 9999 ? this.$t('Original') : el
        items.push(
            { text : text , value : el }
        )
      })
      return items
    }

  },
  watch : {
    selectedFolder() {
      this.initPage()
    },
    filtersData : {
      handler() {
        this.initPage()
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('storage', ['GET_STORAGE_INFO' , 'DELETE_FILE' , 'EDIT_FILE_NAME']),
    ...mapActions('file_browser', [
        'ADD_EDIT_FOLDER' ,
        'GET_FILE_BROWSER',
        'MOVE_TO_FOLDER',
        'GET_FILE_FULL_DATA',
        'DELETE_FOLDER',
        'DELETE_MULTIPLE',
    ]),
    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),


    async loadMore(offset , itemPerPage) {

      if ( (offset + 1) > this.items.length && (offset < this.total) ) {

        let data = this.COPY(this.ajaxFiltersInput)
        data.offset = offset
        data.limit = itemPerPage

        let result = await this.GET_FILE_BROWSER(data)

        if ( !result ) {
          this.loading = false
          return
        }
        this.items = [...this.items , ...result.items]
      }

    },

    handleDelete() {
      if ( !this.isSelectedFolder) {
        this.selectedFile = this.COPY(this.selectedItem)
        this.deleteFile()
      } else {
        this.deleteFolder()
      }
    },

    async handleMultipleDelete() {

      const files = this.bufferItems.filter(el => !el.is_folder).map( el => el.uuid)
      const folders = this.bufferItems.filter(el => el.is_folder).map( el => el.uuid)

      const data = {files,folders}

      let result = await this.DELETE_MULTIPLE(data)

      if (!result) {
        return this.ERROR(this.$t('NetworkError'))
      }

      this.items = this.items.filter( el => !this.bufferItemsIds.includes(el.uuid))
      this.sidebarUpdateTrigger++
      this.bufferItemsIds = []
      this.displayMultipleDeleteDialog = false
    },

    // Files 
    async downloadFile(item , size = null) {
      let result = await this.GET_PRIVATE_FILE({uuid : item.uuid , force : true} )
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');

      if(typeof result.url === 'object' && result.url !== null) {
        // Extract first key's value
        if ( size && result.url[size]) {
          result.url = result.url[size];
        } else {
          var firstKey = Object.keys(result.url)[0];
          result.url = result.url[firstKey];
        }
      }

      a.href = result.url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    async addFile(event) {
      this.items.push(event)
      this.displayUpload = false
    },
    async editFile() {

      let result = await this.EDIT_FILE_NAME(this.selectedFile)
      if ( !result ) {
        return
      }

      let index = this.items.findIndex(el=>el.uuid === this.selectedFile.uuid )
      if ( index === -1 ) {
        return
      }

      this.items[index].name= this.selectedFile.name + '.' + this.selectedFile.ext
      this.displayDialog = false


    },
    async deleteFile() {

      let result = await this.DELETE_FILE(this.selectedFile.uuid)
      if ( !result ) {
        return
      }

      let index = this.items.findIndex(el=>el.uuid === this.selectedFile.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index,1)
      this.displayDialog = false
      this.displayDeleteDialog = false

      this.storageSize = (this.storageSize - (this.selectedFile.size/1000/1000/1000) ).toFixed(2)
      this.notify(this.$t('FileDeleted'))
      this.selectedFile = {}

    },

    // Folders
    async addEditFolder() {
      if ( this.selectedFolder ) {
        this.folderEntityData.parent = this.selectedFolder
      }
      let result = await this.ADD_EDIT_FOLDER(this.folderEntityData)
      if ( !result ) {
        return
      }
      if ( this.newFolder ) {
        let lastFolderIndex = this.items.filter(el => el.is_folder).length
        if (lastFolderIndex < 0 ) { lastFolderIndex = 0 }
        this.items.splice(lastFolderIndex , 0 , result)
        this.folders.push(result)
      } else {
        let index = this.items.findIndex(el => el.uuid === this.folderEntityData.uuid)
        if ( index === -1) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
      }
      this.sidebarUpdateTrigger++
      this.displayFolderDialog = false
    },
    async moveToFolder(item = null) {
      if ( !item ) {
        item = this.selectedItem
      }
      let data = {
        folder_id : item.uuid,
        items : this.bufferItems.map( el=> ({ is_folder : el.mime === 'folder' , id : el.uuid }))
      }
      let result = await this.MOVE_TO_FOLDER(data)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.items = this.items.filter( el => !this.bufferItemsIds.includes(el.uuid))
      this.sidebarUpdateTrigger++
      this.bufferItemsIds = []
      this.displayMoveToFolder = false
    },
    async deleteFolder() {
      let result = await this.DELETE_FOLDER(this.selectedItem.uuid)
      if ( !result ) {
        return this.ERROR()
      }
      this.displayDeleteDialog = false
      this.sidebarUpdateTrigger++
      this.initPage()
    },

    // Dialogs
    async openDialog(file) {

      let expl = file.name.split('.')
      let ext = expl[expl.length -1]
      expl.splice(-1,1);
      let name = expl.join('.')
      this.selectedFile = JSON.parse(JSON.stringify(file))
      this.selectedFile.ext = ext
      this.selectedFile.name = name
      this.previewFileNavigation = this.displayFilePreview ? 'preview' : 'info'
      this.displayDialog = true
      if (!this.selectedFile.is_public && this.previewType === 'image') {

        let result = await this.GET_PRIVATE_FILE(this.selectedFile.uuid)
        if (!result) {
          return
        }

        this.selectedFile.url = result.url
      }

    },
    goToPreviousFolder() {
      this.filtersData = {}
      this.selectedFolder = this.location[this.location.length - 1].uuid
      this.selectedFolderName = this.location[this.location.length - 1].name
      this.location.splice(-1,1)
    },
    openAction(item, isFromContext = false) {
      if ( this.bufferItemsIds.length > 0 ) {
        this.selectItem(item)
      }
      else if ( !item.is_folder) {
        this.openDialog(item)
      } else if (isFromContext && item.is_folder) {
        this.openEditFolder()
      } else {
        this.items = []
        this.location.push({
          uuid : this.selectedFolder,
          name : this.selectedFolderName
        })
        this.filtersData = {}
        this.selectedFolder = item.uuid
        this.selectedFolderName = item.name

      }
    },
    openFileAccessDialog(item) {
      this.selectedFile = this.COPY(item)
      this.displayFileAccessDialog = true
    },
    openMoveToFolder(items) {
      this.displayMoveToFolder = true
      this.bufferItemsIds = this.COPY(items.map(el => el.uuid))
    },
    openDeleteDialog() {
      this.displayDeleteDialog = true
    },
    openUpload() {
      this.displayUpload = true
    },
    openAddFolder() {
      this.folderEntityData = {}
      this.displayFolderDialog = true
      this.newFolder = true
    },
    openEditFolder() {
      this.folderEntityData = this.COPY(this.selectedItem)
      this.displayFolderDialog = true
      this.newFolder = false
    },

    // technical
    selectItem(item) {

      if (this.bufferItemsIds.includes(item.uuid) ) {
        this.bufferItemsIds = this.bufferItemsIds.filter(el => el !== item.uuid)
      }
      else if ( item.is_return ) {
        return
      } else {
        this.bufferItemsIds.push(this.COPY(item.uuid))
      }
    },
    updateSharedCount(value) {

      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid)
      if ( index === -1 ) {
        return
      }
      this.items[index].shared_users_count += value
      this.items = this.COPY(this.items)
    },
    gotoNavigationFolder($event){
      $event.location.unshift({
        uuid : null , name :  this.$t('file_browser.root')
      })
      this.location = $event.location
      this.selectedFolder = $event.uuid
      this.selectedFolderName = $event.name
    },
    gotoBreadcrumbFolder(item) {
      let index = this.location.findIndex(el => el.uuid === item.uuid)
      this.location.splice(index)
      this.selectedFolderName = item.name
      this.selectedFolder = item.uuid
    },
    getIcon(mime) {

      if (!mime ) {
        return 'mdi-file-outline'
      }

      if ( mime.includes('image') ) {
        return 'mdi-file-image-outline'
      }

      if ( mime.includes('video') ) {
        return 'mdi-file-video-outline'
      }

      if ( mime === 'folder'  ) {
        return 'mdi-folder'
      }

      return 'mdi-file-outline'

    },
    getFileType(mime) {
      if (!mime ) {
        return this.$t('File')
      }

      if ( mime.includes('image') ) {
        return this.$t('Image')
      }

      if ( mime.includes('video') ) {
        return this.$t('Video_')
      }

      return this.$t('File')

    },

    async initPage() {
      let result = await this.GET_FILE_BROWSER(this.ajaxFiltersInput)
      if ( !result || result === true) {
        return
      }

      this.items = result.items
      this.total = result.total
    },
  },
  async mounted() {

    this.selectedFolderName = this.$t('file_browser.root')
    this.$store.state.settings.filters = this.filters

    this.initPage()

    let result = await this.GET_STORAGE_INFO()
    if ( !result ) {
      return
    }
    this.storageSize  = result.storage_size
    this.storageSizeQuota  = result.storage_size_quota

  },
  beforeDestroy() {
    this.$store.state.settings.filters = []

  }
}
</script>

<style scoped>
.image-with-chessboard {
  --cell-size: 20px;
  background:
      repeating-conic-gradient(var(--wsBACKGROUND) 0% 25%, transparent 0% 50%)
      50% / 20px 20px
}
</style>